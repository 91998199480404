              <script>
export default {
  data() {
    return {

    }
  }
}
</script>

<template>
               <div id="container04" class="container default full screen footer" style="border-top: 1px dashed white;">
                  <div class="wrapper" style=" margin-top: 0; margin-bottom: 0;">
                     <div class="inner" style="padding-top: 3rem; padding-bottom: 1rem;">
                        <b-row>
                      <b-col>
                       <a class="links" href="https://twitter.com/GLMRApes" target="_blank">
                      <img class="links rs" style="max-height: 84px;" src="./assets/twitter.png">
                </a>
                       </b-col>
                           <b-col>
                            <a class="links px" href="https://discord.gg/H9DH3z2bTV" target="_blank">
                       <img class="links rs" style="max-height: 84px;" src="./assets/discord.png">
                     </a>
                             </b-col>
                           <b-col>
                            <a class="links px" href="https://t.me/+qGh0InPSPc1iMTNk"  target="_blank">
                       <img class="links rs" style="max-height: 84px;" src="./assets/telegram.png">
         </a>
                </b-col>
                </b-row>

                                   <b-row class="pt-3">
                      <b-col>
                       <a class="px w mlink" href="https://the-great-escape.gitbook.io/" target="_blank">DOCS
                        </a>
                       </b-col>
                           <b-col>
                    <a class="px w mlink" href="https://vote.glmrapes.com/" target="_blank">VOTES
                     </a>
                             </b-col>
                           <b-col>
                     <a class="px w mlink" href="https://bananaswap.app/" target="_blank">DEX
                     </a>
                </b-col>
                         <b-col>
                     <a class="px w mlink" href="https://game.glmrjungle.com/" target="_blank">GAME
                     </a>
                </b-col>
                </b-row>

                                             <p id="text16" class="px text-light pt-4"><span class="glmr glow">* GLMR APES DAO *</span><br>2023<br><span style="font-size: 12pt;">❤️ & 🍌</span></p>
                     </div>
                  </div>
               </div>
</template>
<script>
import { ethers } from "ethers";
import { abi, contractAddress } from "./conf.js";
export default {
  data() {
    return {
      isLoading: false,
        walletAddress: '',
        isConnect: new Boolean,
      token: '',
      tokensOwned : '',
      chainID: '',
            provider: {},
      signer: {},
      blockNumber: {},
      gasPrice: {},
      overrides: {},
      w: {},
    ids : [],
    tokendetail : {},
      images : [],
      images1 : [],
    }
  },
  methods: {
              async connect() {

      this.isLoading = true;
      await window.ethereum.enable()
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      let chainId = await provider.getNetwork()
      this.chainId = chainId

     if (this.chainId.chainId != 1284) {
      alert('Please connect to MoonBeam Network (chain ID 1284)')
      this.switchNetwork()
      return
      }

    // Prompt user for account connections
      let signer = provider.getSigner();
      this.walletAddress = signer;
      const contract = new ethers.Contract(contractAddress, abi, signer);
      //let approve = await contract.setApprovalForAll(contractAddress, true);

      this.refresh(provider,  signer, contract);
    },

      async refresh(provider, signer, contract) {
      let token = await contract.totalSupply()
      this.token = ethers.utils.formatUnits(token, 0)
      let connectedWallet = await signer.getAddress();
     this.walletAddress = connectedWallet;
      this.isLoading = false;
      let tokens = await contract.balanceOf(connectedWallet)
      this.tokensOwned = tokens;

    if (tokens > 0) {
    let tokensArray = []
    for (let i = 0; i < tokens.toNumber(); i++) {
      let index = await contract.tokenOfOwnerByIndex(connectedWallet, i)
      tokensArray.push(index.toNumber())
    }
    this.ids = tokensArray;
    let galleryLinks = []
     for (let g = 0; g < tokensArray.length; g++) {
      // galleryLinks.push({id:`${tokensArray[g]}`, url:`https://gateway.pinata.cloud/ipfs/${imgHashes.find(element => element.id == tokensArray[g]).hash}`})
      galleryLinks.push({id:`${tokensArray[g]}`, url:`https://www.glmrapes.com/nfts/${tokensArray[g]}.png`})
     }
     this.images = galleryLinks;

    }
      let vipApe = await contract.walletOfOwner(connectedWallet)
    this.vipApe = vipApe;
    let cHolders = [];

             function fetchHolders() {
      return new Promise((resolve, reject) => {
        fetch('https://blockscout.moonbeam.network/token-autocomplete?q=glma')
        .then(res => res.json())
        //.then(res => res.filter(({ contract_address_hash }) => contract_address_hash === '0x8fbe243d898e7c88a6724bb9eb13d746614d23d6'))
       // .then(res => res.pop().name.match(/<i>(\d+) holder/)[1])
       .then(res => res[0].holder_count)
        .then(res => cHolders.push(res))
        .then(res => console.log(res))
        .then(resolve)
        .catch(reject)
      })
    }
   await fetchHolders()
   this.holders = cHolders[0]
    this.isConnect = true;


    },

  },
  mounted() {


//console.log(elements)

  if (window.ethereum) {
  if (this.signer && this.ids) {
    try {
      this.connect()
    }
    catch (err) {
      this.isConnect = false;
    }
  }
}
},
}
</script>

<template>
      <b-container id="navigation">
      <b-row id="navbar" class="d-flex flex-row-reverse" style="" >

<span class="text-center mx-auto pt-3 py-2 my-auto" style="line-height: 200%;">
     <a class="mt-4 h5 px-3 mlinks text-decoration-none" id="MyApe" href="./"><span class="px mlink" style="text-decoration: none;"><span class="emoj">🌴</span></span></a>
   <a class="mt-4 h5 px-3 mlinks text-decoration-none" id="MyApe" href="./MyApe.html" v-if="isConnect == true"><span class="px mlink" style="text-decoration: none;">MY APES</span></a>
     <a class="mt-4 h5 px-3 mlinks text-decoration-none" id="Bananas" href="./Bananas.html"><span class="px mlink" style="text-decoration: none;">STAKING</span></a>
             <a class="mt-4 h5 px-3 mlinks text-decoration-none" target="_blank"  href="https://vote.glmrapes.com"><span class="px mlink" style="text-decoration: none;">APEMOCRACY</span></a>
     <!-- <a class="mt-4 h5 px-3 mlinks text-decoration-none" target="_blank"  id="DEX" href="https://www.bananaswap.app"><span class="px mlink" style="text-decoration: none;">BANANASWAP</span></a>-->
       <a class="mt-4 h5 px-3 mlinks text-decoration-none" target="_blank" id="Docs" href="https://the-great-escape.gitbook.io/"><span class="px mlink" style="text-decoration: none;">DOCS</span></a>
 </span>

   <b-spinner v-if="isLoading === true" class="text-center my-auto ml-3" variant="success" type="grow" id="loading" label="Busy"></b-spinner>
  <b-button type="button" class="text-center mr-4 my-auto px" variant="outline-light" size="sm" v-if="!isConnect" v-on:click="connect()">Connect Wallet</b-button>
 <div v-if="this.ids > 0">
 <p class=" small px catchy" v-if="isConnect">👋 <span id="walletaddress">{{ walletAddress }}</span></p>
 </div>
 </b-row>
 </b-container>
</template>